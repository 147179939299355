import React from 'react'
import { Link } from 'gatsby'

const Back = (props) => (
  <div className="back-button">
    <Link to={props.location ? props.location : '/'}>
        {props.message ? props.message : 'Back to home'}
    </Link>
  </div>
)

export default Back
