import React from 'react'
import Link from 'gatsby-link'

const Nav = (props) => (
    <nav>
        <ul>
            <li className="home"><Link to="/">Go&nbsp;Home</Link></li>
            <li><Link to="/mining"><span className="optional">Generalized&nbsp;</span>Mining</Link></li>
            <li><Link to="/strategies">Strategies</Link></li>
            <li><Link to="/about">About<span className="optional">&nbsp;Grassfed</span></Link></li>
            <li><Link to="/contact">Contact</Link></li>
        </ul>
    </nav>
)

export default Nav
