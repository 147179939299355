import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Nav from './Nav'
import Back from './Back'
import ReactGA from 'react-ga';

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  let content;

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <Nav/>
        <article>
          {children}
          <div>
            <Back/>
          </div>
        </article>
      </div>
    )
  }

  ReactGA.initialize('UA-134449611-1');
  if (typeof window !== 'undefined') {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Grassfed Network, powered by CoinFund' },
              { name: 'keywords', content: 'blockchain, staking, generalized mining, decentralized networks, decentralization, crypto, cryptoeconomics' },
            ]}
          >
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-134449611-1"></script>
            <html lang="en" />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
